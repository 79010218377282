import React from 'react';
import StateSelect from '../../components/Account/StateSelect';
import CustomFormErrorMessage from '../../ui/CustomFormErrorMessage';
import { useTranslation } from 'react-i18next';

export default function FormattedStateSelect({ country }) {
  const { t } = useTranslation();
  return (
    <div className="form-group col-12 col-md-6">
      <label htmlFor="stateProvinceRegion">
        {t('forms.stateProvinceRegion')}
      </label>
      <StateSelect country={country} />
      <CustomFormErrorMessage name="stateProvinceRegion" />
    </div>
  );
}
