import React from 'react';
import { Redirect } from '@reach/router';

import { useUser } from '@util/providers/AuthProvider';

export default function ProtectedPage({ children }) {
  const { ssoUser } = useUser();

  if (ssoUser) {
    return children;
  } else {
    return <Redirect to="/" noThrow />;
  }
}
