import * as Yup from 'yup';
import i18n from '@i18n/i18n';
//* use i18n key as value for the error messaging
Yup.setLocale({
  mixed: {
    required: 'validation.required',
  },
});

export const TaxExemptSchema = Yup.object().shape({
  country: Yup.string().required(),
  stateProvinceRegion: Yup.string().required(),
  zipPostalCode: Yup.string()
    .required()
    .when('country', {
      is: 'US',
      then: Yup.string().matches(
        /\d{5}([ -]\d{4})?/,
        i18n.t('validation.USZipCode')
      ),
    })
    .when('country', {
      is: 'CA',
      then: Yup.string().matches(
        /[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ ]?\d[ABCEGHJ-NPRSTV-Z]\d/,
        i18n.t('validation.CAZipCode')
      ),
    }),
  acceptTerms: Yup.bool().oneOf([true], i18n.t('validation.required')),
  uploadFile: Yup.mixed().required(),
});

export const user = {
  country: '',
  region: '',
  zipPostalCode: '',
  acceptTerms: false,
  uploadFile: '',
};
