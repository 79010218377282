import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form, Field } from 'formik';
import { navigate } from 'gatsby';
import { user, TaxExemptSchema } from '@data/taxExempt';
import { useUser } from '@util/providers/AuthProvider';
import axios from 'axios';
import URLS from '@config/urls';
import BMOTerms from '@components/Legal/BMOTerms';
import FormGroup from '@util/Forms/FormGroup';
import FileUpload from '@util/Forms/FileUpload';
import FormattedStateSelect from '@util/Forms/FormattedStateSelect';
import CustomFormErrorMessage from '../../ui/CustomFormErrorMessage';

export default function TaxExemptBody() {
  const { ssoUser, bmoUser } = useUser();
  const { t } = useTranslation();
  const formikRef = useRef();
  const [formIsSubmitting, setFormIsSubmitting] = useState(false);
  return (
    <div className="row">
      <div className="col-xl-7">
        <p className="font-italic">{t('taxExempt.us&ca')}</p>

        <p>
          {bmoUser && bmoUser.zuoraInfo
            ? t('taxExempt.taxExemptionProgramExisting')
            : t('taxExempt.taxExemptionProgram')}
        </p>
        <hr />
        <Formik
          enableReinitialize
          initialValues={user}
          validationSchema={TaxExemptSchema}
          ref={formikRef}
          onSubmit={(values, actions) => {
            setFormIsSubmitting(true);
            var formData = new FormData();
            formData.append('uploadFile', values.uploadFile);
            axios({
              method: 'post',
              url:
                URLS.TAX_EXEMPT +
                '?state=' +
                values.stateProvinceRegion +
                '&zip=' +
                values.zipPostalCode +
                '&country=' +
                values.country,
              data: formData,
              headers: {
                Authorization: `Bearer ${ssoUser.access_token}`,
              },
            })
              .then(res => {
                //* if trial was created successfully
                setFormIsSubmitting(false);
                if (res.status === 200) {
                  navigate('/taxexempt/thankyou');
                }
              })
              .catch(err => {
                // TODO: handle error in the UI
                console.error(err.message);
                //* activate submit button to allow for resubmission
                setFormIsSubmitting(false);
              });
          }}
          render={({ isValid, isSubmitting, values, setFieldValue }) => {
            return (
              <Form>
                <div className="row">
                  <div className="form-group col-12 col-md-6">
                    <label htmlFor="country">{t('forms.country')}</label>
                    <Field
                      component="select"
                      name="country"
                      className="custom-select"
                      id="countryField"
                      onChange={e => {
                        //* reset state dropdown
                        values.hasOwnProperty('stateProvinceRegion') &&
                          setFieldValue('stateProvinceRegion', '');
                        setFieldValue('country', e.target.value);
                      }}
                    >
                      <option value="">{t('forms.selectYourCountry')}</option>
                      <option key="US" value="US">
                        {t('unitedStates')}
                      </option>
                      <option key="CA" value="CA">
                        {t('canada')}
                      </option>
                    </Field>
                    <CustomFormErrorMessage name="country" />
                  </div>
                </div>
                <div className="row">
                  <FormattedStateSelect country={values.country} />
                  <FormGroup
                    name="zipPostalCode"
                    fieldProps={{
                      maxLength: '20',
                    }}
                  />
                </div>
                <div className="row pb-3">
                  <FileUpload size="col-md-6" setFile={setFieldValue} />
                </div>
                <BMOTerms />
                <div className="row pt-3">
                  <div className="col-12">
                    <button
                      className="btn btn-primary"
                      type="submit"
                      name="submitButton"
                      id="submitButton"
                      disabled={!isValid || formIsSubmitting}
                    >
                      {formIsSubmitting && (
                        <>
                          <span
                            className="spinner-corner spinner-corner-sm align-middle mt-n1"
                            role="status"
                            aria-hidden="true"
                          />{' '}
                        </>
                      )}
                      {formIsSubmitting
                        ? t('status.submittingRequest')
                        : t('action.submit')}
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        window ? window.history.back() : navigate('/');
                      }}
                      id="TaxExemptBack"
                      className="btn btn-outline-secondary"
                      disabled={formIsSubmitting}
                    >
                      {t('action.back')}
                    </button>
                    {!(bmoUser && bmoUser.zuoraInfo) && (
                      <button
                        type="button"
                        onClick={() => navigate('/')}
                        id="TaxExemptCancel"
                        className="btn btn-outline-secondary"
                        disabled={formIsSubmitting}
                      >
                        {t('action.cancel')}
                      </button>
                    )}
                  </div>
                </div>
              </Form>
            );
          }}
        />
      </div>
    </div>
  );
}
