import React from 'react';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';
import { stateList } from '../../data/states';

export default function StateSelect({ country }) {
  const { t } = useTranslation();

  const countryData = stateList[country];

  return (
    <Field
      component="select"
      name="stateProvinceRegion"
      className="custom-select"
      id="stateProvinceRegion"
      disabled={!countryData}
    >
      <option value="">
        {countryData ? t(countryData.label) : t('forms.countryNotSupported')}
      </option>
      {countryData &&
        countryData.values &&
        Object.entries(countryData.values).map((item, value) => (
          <option id={item[0]} key={item[0]} value={item[0]}>
            {item[1]}
          </option>
        ))}
    </Field>
  );
}
