import React from 'react';
import { useTranslation } from 'react-i18next';
import ProtectedPage from '@components/ProtectedPage';
import PageHeader from '@ui/PageHeader';
import SEO from '@components/seo';
import TaxExemptBody from '@components/TaxExempt/TaxExemptBody';

export default function TaxExemptStart() {
  const { t } = useTranslation();

  return (
    <ProtectedPage>
      <SEO title={t('page:taxExempt')} />
      <PageHeader className="mb-4">{t('page:taxExempt')}</PageHeader>

      <TaxExemptBody />
    </ProtectedPage>
  );
}
